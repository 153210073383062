:root {
  --color-dark-blue: #035072;
  --color-light-blue: #00a0d6;
  --color-yellow: #fbcb42;
  --color-light-light-blue: #d8eef6;
  --color-green: #95c34b;
  --color-orange: #f0a14b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.offInputStyle:focus {
  outline: none;
}

input::file-selector-button {
  border: 2px solid var(--color-dark-blue);
  border-radius: 15px;
  background-color: transparent;
  font-style: italic;
  color: var(--color-dark-blue);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}

/* anitmations */

.animation-scale:hover {
  transform: scale(1.05);
}

.btn-warning {
  background-color: var(--color-yellow);
}

.btn-success {
  background-color: var(--color-green);
}

/* styles */

input {
  border-radius: 10px;
  padding: 5px 5px 5px 15px;
  border: 1px solid lightgray;
}

textarea {
  /* border-radius: 10px; */
  padding: 5px 5px 5px 15px;
  border: 1px dashed lightgray;
}

.box-style {
  border: 1px solid var(--color-dark-blue);
  background-color: var(--color-light-light-blue);
}

.shipping-input {
  width: 100px;
}

.check {
  background: var(--color-green);
  color: white;
  scale: 1.2;
  border: 1px solid white;
}

.notCheck {
  border: 1px solid black;
}

.queue tr:nth-child(2n + 1) {
  background: var(--color-light-light-blue);
  padding: 9px;
}

.list tr:nth-child(2n + 1) {
  background: var(--color-light-light-blue);
  padding: 9px;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* background: rgb(200, 200, 200); */
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: var(--color-dark-blue);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-blue);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-dark-blue);
}

.styled-table.sticky-header th {
  position: sticky;
  top: 0;
  background-color: var(--color-dark-blue);
}

.styled-table {
  overflow-x: scroll;
  border-collapse: collapse;
  /* margin: 25px 0; */
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}
.styled-table thead tr {
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: left;
  border-bottom: 1px solid white;
}

.styled-table thead th {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.styled-table tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}

.styled-table-cutsom tr:first-child th:last-child {
  border-top-left-radius: 0px !important;
}

.styled-table tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.styled-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.custom thead th {
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: left;
}

.wrong-table thead tr {
  background-color: #dc3545;
}

.good-table thead tr {
  background-color: var(--color-green);
}

.styled-table th,
.styled-table td {
  text-align: center;
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-child {
  border-bottom: none;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.scroll-padding {
  scroll-padding: 20px !important;
  scroll-margin: 20px !important;
}

/* Set a fixed scrollable wrapper */
.tableWrap {
  max-height: 75vh;
  min-width: 70vw;
  overflow: auto;
}
/* Set header to stick to the top of the container. */
.styled-table-2 thead tr th {
  position: sticky;
  top: 0;
}

/* If we use border,
we must use table-collapse to avoid
a slight movement of the header row */
.styled-table-2 table {
  border-collapse: collapse;
}

.styled-table-2 th {
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: center;
  border-bottom: 1px solid white;
  padding: 5px 10px;
}

.styled-table-2 table td {
  padding: 20px 20px;
  text-align: center;
}
.styled-table-2 tbody tr {
  border-bottom: 2px solid #e8e8e8;
}

.styled-table-2 tbody tr:hover {
  background: #e6f7ff;
}

.styled-table-2 tr:nth-child(2n + 1) {
  background: var(--color-light-light-blue);
  padding: 9px;
}

.styled-table-2 tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table-2 thead tr {
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: left;
  border-bottom: 1px solid white;
}

.styled-table-2 tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}

.styled-table-2 tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.styled-table-2 tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table-2 tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table-2 tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table-dashboard {
  overflow-x: scroll;
  border-collapse: collapse;
  /* margin: 25px 0; */
  font-size: 0.7em;
  font-family: sans-serif;
  min-width: 400px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}
.styled-table-dashboard thead tr {
  background-color: var(--color-dark-blue);
  color: #ffffff;
  text-align: left;
  border-bottom: 1px solid white;
}

.styled-table-dashboard thead th {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.styled-table-dashboard tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}

.styled-table-dashboard tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.styled-table-dashboard tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table-dashboard tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table-dashboard tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table-dashboard th,
.styled-table-dashboard td {
  text-align: center;
  padding: 8px 5px;
}

.styled-table-dashboard tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-dashboard tbody tr:last-child {
  border-bottom: none;
}

.styled-table-dashboard tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.fade__in__animation {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.styled-table-summary tbody tr:last-child {
  background-color: #035072;
  color: #fff;
}

.inc-dec-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.inc-dec {
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.inc-dec:hover {
  background-color: rgb(55, 146, 152);;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
